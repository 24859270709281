/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { AUTH } from '@constants/routes';
import { getLocalStorageItem, setLocalStorageItem } from '@utils/storage';
import { SideNavBar, Spinner, Topbar } from '@components';
import {
  setCurrentUser,
  setMascotDetails,
  setRole,
  setSelectedSubjectDetails
} from '@features/authentication/userSlice';
import { useGetUserDetailsQuery } from '@features/authentication/queries/authQueries';
import { ApiError, RoleType } from '@types';
import {
  useLazyGetAllMascotsQuery,
  useLazyGetMascotsForParentQuery,
  useLazyGetStudentMascotsQuery
} from '@features/gamification/queries/mascotQueries';
import { buildQueryString } from '@utils/queryStringBuilder';
import useNotify from '@hooks/notify';
import { mascotThemes } from '@features/homeworks/config';
import { setErrorImageInLocalStorage } from '@utils/generic';

const MainLayout = () => {
  const { createErrorAlert } = useNotify();
  const { selectedSubject, selectedStudentForParent } = useSelector(
    (state: any) => state.user
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data,
    isError: userError,
    isLoading
  } = useGetUserDetailsQuery(null, {
    refetchOnMountOrArgChange: true
  });

  const [getSubjectListForStudent, { isLoading: isSubjectListLoading }] =
    useLazyGetStudentMascotsQuery();
  const [
    getSubjectListForParent,
    { isLoading: isSubjectListForParentsLoading }
  ] = useLazyGetMascotsForParentQuery();

  const [getMascots, { isLoading: isMascotImagesLoading, data: mascotData }] =
    useLazyGetAllMascotsQuery();

  const token = getLocalStorageItem('token');

  const getSubjectForStudent = async () => {
    const response = await getSubjectListForStudent();
    if ('data' in response) {
      if (response?.data?.data?.length) {
        const allSubjectsHaveMascot = response?.data?.data?.every(
          (subject: any) => subject.mascotId !== null
        );
        if (allSubjectsHaveMascot) {
          dispatch(
            setSelectedSubjectDetails({
              selectedSubject: response?.data?.data[0]
            })
          );
        } else {
          navigate('/select-subject');
        }
      }
    } else if ('error' in response) {
      const error = response.error as ApiError;
      createErrorAlert(error?.data?.message || '');
    }
  };
  const getSubjectForParents = async () => {
    const response = await getSubjectListForParent({
      studentId: selectedStudentForParent?.id || data?.data?.students?.[0]?.id
    });
    if ('data' in response) {
      if (response?.data?.data?.length) {
        dispatch(
          setSelectedSubjectDetails({
            selectedSubject: response?.data?.data[0]
          })
        );
      }
    } else if ('error' in response) {
      const error = response.error as ApiError;
      createErrorAlert(error?.data?.message || '');
    }
  };

  useEffect(() => {
    if (data?.data) {
      dispatch(setCurrentUser({ user: data?.data }));
      if (data?.data?.accessLevels[0]?.name === 'Student') {
        dispatch(setRole({ role: RoleType.STUDENT }));
        getSubjectForStudent();
      } else if (data?.data?.accessLevels[0]?.name === 'Parent') {
        dispatch(setRole({ role: RoleType.PARENT }));
        getSubjectForParents();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (selectedSubject?.subjectId)
      if (selectedSubject?.mascotId) {
        (async () => {
          const queryParam = {
            mascotId: selectedSubject?.mascotId
          };

          const response = await getMascots({
            param: buildQueryString(queryParam)
          });
          if ('data' in response) {
            dispatch(setMascotDetails({ mascotDetails: response.data?.data }));
            setErrorImageInLocalStorage(response.data?.data, selectedSubject);
          } else if ('error' in response) {
            const error = response.error as ApiError;
            createErrorAlert(error?.data?.message);
          }
        })();
      } else {
        dispatch(setMascotDetails({ mascotDetails: [] }));
        setLocalStorageItem(
          'errorMascotImgUrl',
          mascotThemes[0]?.errorScreen?.mascotImage
        );
      }
  }, [selectedSubject?.subjectId]);

  useEffect(() => {
    if (data?.data?.accessLevels[0]?.name === 'Parent') getSubjectForParents();
  }, [selectedStudentForParent?.id]);

  useEffect(() => {
    if (userError) navigate(AUTH.LOGIN);
  }, [userError]);

  useEffect(() => {
    if (!token) navigate(AUTH.LOGIN);
  }, []);

  if (
    isLoading ||
    isSubjectListLoading ||
    isSubjectListForParentsLoading ||
    isMascotImagesLoading ||
    !mascotData
  ) {
    return <Spinner />;
  }

  return (
    <div className="flex h-screen w-screen overflow-hidden">
      <div className="size-full">
        <div className="hidden sm:block">
          <SideNavBar userDetails={data?.data} />
        </div>
        <div className="sm:hidden">
          <Topbar />
        </div>
        <div className="h-[calc(100%-60px)] overflow-y-auto bg-yellow sm:h-screen">
          <div className="wrapper custom-scroll-bar" id="main-layout-wrapper">
            <Suspense
              fallback={
                <div className="absolute top-1/2 flex size-full -translate-y-1/2 items-center justify-center">
                  <Spinner />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
