import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RoleType } from '@types';

const initialState = {};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (
      state,
      { payload: { user } }: PayloadAction<{ user: any }>
    ) => ({
      ...state,
      currentUser: { ...user, mascotId: 0 }
    }),
    setRole: (
      state,
      { payload: { role } }: PayloadAction<{ role: RoleType }>
    ) => ({
      ...state,
      role
    }),
    setSelectedSubjectDetails: (
      state,
      { payload: { selectedSubject } }: PayloadAction<{ selectedSubject: any }>
    ) => ({
      ...state,
      selectedSubject
    }),
    setMascotDetails: (
      state,
      { payload: { mascotDetails } }: PayloadAction<{ mascotDetails: any }>
    ) => ({
      ...state,
      mascotDetails
    }),
    setSelectedStudentForParent: (
      state,
      {
        payload: { selectedStudentForParent }
      }: PayloadAction<{ selectedStudentForParent: any }>
    ) => ({
      ...state,
      selectedStudentForParent
    }),
    resetUserState: () => initialState
  }
});

export const {
  setCurrentUser,
  resetUserState,
  setRole,
  setSelectedSubjectDetails,
  setMascotDetails,
  setSelectedStudentForParent
} = userSlice.actions;
export default userSlice.reducer;
