import { useSelector } from 'react-redux';

import { getImageUrl } from '@features/gamification/utils';
import { mascotThemes } from '@features/homeworks/config';

const useMascotTheme = () => {
  const { mascotDetails, selectedSubject } = useSelector(
    (state: any) => state.user
  );

  const currentLevelMascots =
    mascotDetails &&
    mascotDetails[0]?.mascotImages?.filter(
      (image: any) => image?.stage === (selectedSubject?.level || 1)
    );
  if (currentLevelMascots) {
    return {
      homeworkCard: {
        mascotImage: {
          active: getImageUrl({
            type: 'Happy',
            imageList: currentLevelMascots
          }),
          completedHappy: getImageUrl({
            type: 'Happy',
            imageList: currentLevelMascots
          }),
          completedNeutral: getImageUrl({
            type: 'Neutral',
            imageList: currentLevelMascots
          }),
          completedSad: getImageUrl({
            type: 'Sad',
            imageList: currentLevelMascots
          }),
          overdue: getImageUrl({ type: 'Sad', imageList: currentLevelMascots })
        },
        textColor: 'text-blue700',
        progressBar: {
          bgColor: '#32C175',
          fgFromColor: '#E89241',
          fgToColor: '#EDAB6D'
        }
      },
      errorScreen: {
        mascotImage: getImageUrl({
          type: 'Sad',
          imageList: currentLevelMascots
        })
      },
      resultDialog: {
        correctMascotImage: getImageUrl({
          type: 'Happy',
          imageList: currentLevelMascots,
          isThumbnail: true
        }),
        partiallyCorrectMascotImage: getImageUrl({
          type: 'Neutral',
          isThumbnail: true,
          imageList: currentLevelMascots
        }),
        incorrectMascotImage: getImageUrl({
          type: 'Sad',
          isThumbnail: true,
          imageList: currentLevelMascots
        })
      },
      summaryDialog: {
        mascotImage: getImageUrl({
          type: 'Happy',
          imageList: currentLevelMascots
        })
      }
    };
  }
  return mascotThemes[0];
};

export default useMascotTheme;
